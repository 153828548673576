body {
    font-size: 17px;
}

a {
    @apply font-semibold;
    @apply no-underline;
    @apply text-indigo-600;

    &:hover {
        @apply text-indigo-800;
    }
}

blockquote {
    @apply border-indigo-400;
    @apply border-l-4;
    @apply font-normal;
    @apply italic;
    @apply my-8;
    @apply pl-6;
    @apply text-gray-800;
    @apply text-lg;
}

code {
    @apply bg-gray-200;
    @apply px-2;
    @apply py-px;
    @apply rounded;
    @apply text-sm;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply leading-tight;
    @apply mb-4;
    @apply mt-8;
    @apply text-gray-900;

    &:first-child {
        @apply mt-0;
    }
}

h1 {
    @apply font-extrabold;
    @apply text-5xl;
}

h2 {
    @apply font-bold;
    @apply text-4xl;
}

h3 {
    @apply font-bold;
    @apply text-3xl;
}

h4 {
    @apply font-normal;
    @apply text-2xl;
}

h5 {
    @apply font-normal;
    @apply text-xl;
}

h6 {
    @apply font-light;
    @apply text-lg;
}

hr {
    @apply border-b;
    @apply border-indigo-200;
    @apply my-12;
    @apply rounded-full;
}

li {
    ul,
    ol {
        @apply my-0;
    }
}

ol,
ul {
    @apply my-4;
}

ol {
    @apply list-decimal;
}

p {
    @apply my-3;

    @screen md {
        @apply my-6;
    }
}

pre:not(.skip) {
    @apply bg-gray-200;
    @apply leading-loose;
    @apply my-6;
    @apply overflow-x-auto;
    @apply p-4;
    @apply rounded;
    @apply shadow;
    @apply text-base;

    code {
        @apply bg-transparent;
        @apply block;
        @apply p-0;
    }
}

::selection {
    @apply bg-indigo-500;
    @apply text-white;
}
